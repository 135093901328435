import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { breakpoints, layout } from '../theme'
import { Button, Pulse } from '@monbanquet/crumble'
import SalesPictureTitle from '../components/SalesPictureTitle'
import { EventContext } from '../components/context/EventContext'
import { navigate } from '../components/Link'
import { useWithDelay } from '../hooks/useWithDelay'
import DemandeLayout from '../components/DemandeLayout'
import { computeNextPageWithBaseUri } from '../util/compute-next-page'
import { graphql } from 'gatsby'

const FROM_PG_MENU_PAGE = 'From_PgMenuPage'

const PgMenu = data => {
  const {
    state: { menu },
    dispatch: dispatchEventCtx,
  } = useContext(EventContext)

  const submit = () => {
    dispatchEventCtx({
      type: 'UPDATE_CATEGORY',
      payload: {
        category: data?.data?.page?.slug,
        multiMoment: false,
        moment: data?.data?.page?.momentInSalesforce,
        fromLanding: true,
      },
      analytics: { label: FROM_PG_MENU_PAGE },
    })
    navigate(computeNextPageWithBaseUri('demande', 'pg-menu'), {
      state: { next: true },
    })
  }
  const [submitWithDelay, isDelayed] = useWithDelay(submit, 200)
  const [chooseMenu, setChooseMenu] = useState(false)

  const confirm = () => {
    if (!menu) {
      return setChooseMenu(true)
    }
    submitWithDelay()
  }

  const handelSelectMenu = innerMenu => {
    setChooseMenu(false)
    dispatchEventCtx({
      type: 'UPDATE_MENU',
      payload: { menu: innerMenu },
      analytics: { label: FROM_PG_MENU_PAGE },
    })
    submitWithDelay()
  }

  return (
    <StyledPgMenu>
      <DemandeLayout>
        {
          <div>
            <SalesPictureTitle
              title={data?.data?.page?.title?.title}
              subTitle={
                <>
                  {`Avant toute chose,`}
                  <br />
                  {`merci de sélectionner ci-dessous la formule qui vous intéresse.`}
                </>
              }
            />

            <div className="menus">
              <div className={`choose-menu ${chooseMenu ? 'error' : ''}`}>
                <span>Séléctionner une formule</span>
              </div>
              {data?.data?.page?.menus?.menus.split(';').map(innerMenu => (
                <Button
                  key={innerMenu}
                  className={`menu-btn ${menu === innerMenu ? 'selected' : ''}`}
                  onClick={() => handelSelectMenu(innerMenu)}
                >
                  {innerMenu}
                </Button>
              ))}
            </div>

            <div className="btn-bar">
              <Button id="previous" style={{ display: 'none' }} />
              <Button id="next" type="submit" onClick={confirm}>
                {isDelayed ? <Pulse /> : <span>Suivant &rarr;</span>}
              </Button>
            </div>
          </div>
        }
      </DemandeLayout>
    </StyledPgMenu>
  )
}

const StyledPgMenu = styled.div`
  @media (max-width: ${breakpoints.mobile}px) {
    .page-content {
      margin-top: ${layout.navbarHeight.mobile}px;
      margin-bottom: 50px;
    }
  }
  .menus {
    margin-top: 40px;
    .menu-btn {
      margin: 15px 5px;
      min-width: 190px;
      background: #ffffff;
      color: hsla(230, 10%, 30%, 1);
    }
    .selected {
      color: #bc8046;
    }
  }
  .choose-menu {
    color: transparent;
  }
  .error {
    color: red;
  }
  #next {
    margin-left: auto;
    margin-right: 0;
  }
`

export const query = graphql`
  query($slug: String!) {
    page: contentfulPgMenu(slug: { eq: $slug }) {
      slug
      title {
        title
      }
      menus {
        menus
      }
      momentInSalesforce
    }
  }
`

export default PgMenu
export { StyledPgMenu }
